a.back-to-top {
	display: none;
	width: 60px;
	height: 60px;
	text-indent: -9999px;
	position: fixed;
	z-index: 999;
	right: 20px;
	bottom: 20px;
	background: rgba(167, 167, 167, 0.5) url("arrow-up.png") no-repeat center 50%;
	background-size: 40px 30px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}
a.back-to-top:hover {
	background: rgba(147, 147, 147, 0.5) url("arrow-up.png") no-repeat center 50%;
	background-size: 40px 30px;
}